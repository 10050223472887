/*
 * @Author: gaylen
 * @Date: 2021-05-06 14:37:41
 * @LastEditTime: 2021-05-19 11:44:34
 * @LastEditors: gaylen
 * @Description: 
 * @FilePath: \web_admin\src\api\user.js
 * 新闻api
 */



import * as http from '../util/request'
/**
 * 测试
 * @param {object} data   
 */


/**
 * @description: 
 * @param {*} query
 * @return {*}
 */
const login = (query) => {
    console.log(query)
  return http.post(`oauth/user/token?password=${query.password}&username=${query.username}`,query)
}

const ossFileUpdate = (query) => {
  return http.post('/aliyunOss/uploadFile',query)
}

export {
    login,
    ossFileUpdate
}
