/*
 * @Author: gaylen
 * @Date: 2021-04-30 11:15:01
 * @LastEditTime: 2021-04-30 11:20:20
 * @LastEditors: gaylen
 * @Description: 
 * @FilePath: \web_admin\src\store\modules\settings.js
 * 没啥想说的
 */


const state = {
  fixedHeader:false,
  showSettings:undefined,
  sidebarLogo:false,
  
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  }
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

