<!--
 * @Author: gaylen
 * @Date: 2021-04-30 10:44:35
 * @LastEditTime: 2021-06-24 16:29:02
 * @LastEditors: gaylen
 * @Description: 
 * @FilePath: \web_admin\src\views\layout\components\AppMain.vue
 * 没啥想说的
-->
<template>
  <section class="app-main">
   <div class="main_content">
      
       



 <router-view v-slot="{ Component }">
    <keep-alive>
        <component :is="Component" />
      </keep-alive>
  </router-view>

 
   </div>
  </section>
</template>
<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style scoped>
.app-main {
  /*50 = navbar  */
  box-sizing: border-box;
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
   background-color: rgba(242, 242, 242, 1);
    
      display: grid;
      padding: 15px 30px;
}
.main_content{
      background-color: #fff;
       width: 100%;
       height: 100%;
}
.fixed-header+.app-main {
  padding-top: 50px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
a:active{
  text-decoration-color: transparent;
}
</style>
