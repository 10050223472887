<!--
 * @Author: gaylen
 * @Date: 2021-04-30 10:49:14
 * @LastEditTime: 2021-04-30 10:54:16
 * @LastEditors: gaylen
 * @Description: 
 * @FilePath: \web_admin\src\views\layout\components\Sidebar\Link.vue
 * 没啥想说的
-->
<template>
  <component :is="type" v-bind="linkProps(to)">
    <slot />
  </component>
</template>

<script>
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

export default {
  props: {
    to: {
      type: String,
      required: true
    }
  },
  computed: {
    isExternal() {
      return isExternal(this.to)
    },
    type() {
      if (this.isExternal) {
        return 'a'
      }
      return 'router-link'
    }
  },
  methods: {
    linkProps(to) {
      if (this.isExternal) {
        return {
          href: to,
          target: '_blank',
          rel: 'noopener'
        }
      }
      return {
        to: to
      }
    }
  }
}
</script>
