/*
 * @Author: gaylen
 * @Date: 2021-04-30 11:15:01
 * @LastEditTime: 2021-05-24 17:38:36
 * @LastEditors: gaylen
 * @Description: 
 * @FilePath: \web_admin\src\store\modules\common.js
 * 没啥想说的
 */

import { getBasicData } from '../../api/common'

const getDefaultState = () => {
  return {
    BasicData:{}
  }
}

const state = getDefaultState()

const mutations = {
  setBasicData: (state, data) => {
    state.BasicData = data
  },
}

const actions = {
    // 获取基础数据
    getBasicDatas({ commit }, userInfo) {
      return new Promise((resolve, reject) => {

        resolve()
        
        // getBasicData().then(res => {
        //   // TODO 基础数据接口有问题  还没调通  其他的调好了 轮播页面有使用(computer --> basicDAta)
        //   commit('setBasicData',res.data)
        //   resolve()
        // }).catch(error => {
        //   reject(error)
        // })
      })
    }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

