<!--
 * @Author: gaylen
 * @Date: 2021-04-27 15:45:51
 * @LastEditTime: 2023-11-20 12:03:23
 * @LastEditors: gaylen it7@importgogo.com
 * @Description: 
 * @FilePath: \web_admin\src\App.vue
 * 没啥想说的
-->
<template>
  <div id="app">
     <router-view v-slot="{ Component, route }">
    <transition :name="route.meta.transitionName">
      <keep-alive v-if="route.meta.keepAlive">
        <component :is="Component" />
      </keep-alive>
      <component v-else :is="Component" />
      
    </transition>
  </router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
     this.$store.dispatch('common/getBasicDatas')
  },
}
</script>
<style lang="scss">
  #app{
    min-height:100vh
  }
  .el-overlay.is-message-box {
    text-align: center;
}
a{
  text-decoration: none  !important;
}
.el-form-item__content{
    display: inline-block;
}
.el-overlay.is-message-box:after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 0;
    vertical-align: middle;
}
  .el-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2001;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    overflow: auto;
}
  .flex_center{
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.el-radio-group::v-deep
  .el-radio-button__orig-radio:checked
  + .el-radio-button__inner {
  background-color: #3f72af  !important;
  border-color: #3f72af  !important;
}
</style>