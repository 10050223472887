/*
 * @Author: gaylen
 * @Date: 2021-04-27 16:55:05
 * @LastEditTime: 2021-05-20 14:09:49
 * @LastEditors: gaylen
 * @Description: 
 * @FilePath: \web_admin\src\store\index.js
 * 没啥想说的
 */
import { createStore } from 'vuex'

import getters from './getters'
import app from './modules/app'
import settings from './modules/settings'
import user from './modules/user'
import common from './modules/common'

export default createStore({
    modules: {
        app,
        settings,
        common,
        user
      },
      getters
})