/*
 * @Author: gaylen
 * @Date: 2021-04-28 10:32:01
 * @LastEditTime: 2023-09-14 09:25:55
 * @LastEditors: gaylen it7@importgogo.com
 * @Description: 
 * @FilePath: \web_admin\src\util\request.js
 * 没啥想说的
 */
const getToken = () => {
  return sessionStorage.getItem('TOKEN')
}
function removeEmpty(obj) {
  return obj
  for (var prop in obj) {
    if (obj[prop] === null || obj[prop] === undefined || obj[prop] === '') {
      delete obj[prop];
    } else if (typeof obj[prop] === 'object') {
      removeEmpty(obj[prop]); // 递归调用
      if (Object.keys(obj[prop]).length === 0) {
         obj[prop] = {}
      }
    }
  }
  return obj;
}

const errorCode = {
  '401': '认证失败，无法访问系统资源',
  '403': '当前操作没有权限',
  '404': '访问资源不存在',
  'default': '系统未知错误，请反馈给管理员'
}
import axios from 'axios'
import { ElMessageBox,ElNotification  } from 'element-plus';
 
import router from '../router'
import store from '../store'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 5*60*1000 // request timeout
})

service.interceptors.request.use(config => {
  const isToken = (config.headers || {}).isToken === false
  config.headers['Authorization'] = 'Bearer'
  if (getToken() && !isToken) {
  
    config.headers['Authorization'] = 'Bearer' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config;
}, err => Promise.reject(err))


service.interceptors.response.use(res => {

  // 未设置状态码则默认成功状态
  const code = res.data.resp_code || '200';
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode['default']
  console.log(code)
  if (code === '401') {
    if(!store.state.app.hasElMessageBox){
      store.commit('app/chenge_hasElMessageBox',true)  
      ElMessageBox.alert('登录状态已过期，需要重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).then(() => {
        store.commit('app/chenge_hasElMessageBox',false) 
        router.push('/login')
      }).catch(()=>{
        store.commit('app/chenge_hasElMessageBox',false) 
      })
    }
    
  } else if (code === '500') {
    ElMessageBox({
      message: msg,
      type: 'error'
    })
    return Promise.reject(new Error(msg))
  } else if (code !== '200') {
    ElNotification.error({
      title: msg
    })
    return Promise.reject('error')
  } else {
    return res
  }
},
  error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "服务器错误";
    }
    else if (message.includes("timeout")) {
      message = "请求连接超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "服务器" + message.substr(message.length - 3) + "异常";
    }
    if(!store.state.app.hasElMessageBox){
      store.commit('app/chenge_hasElMessageBox',true)  
      ElMessageBox({
        message: message||'error',
        type: 'error',
        duration: 5 * 1000
      }).then((res) => {
        store.commit('app/chenge_hasElMessageBox',false) 
      }).catch((error) => {
        store.commit('app/chenge_hasElMessageBox',false) 
      })
    }
    
    return Promise.reject(error)
  }
)



/**
 * @param  {string} url
 * @param  {object} params={}
 */
const fetch = (url, params = {}) => {
  console.log(params)
  params = removeEmpty(params)
  return new Promise((resolve, reject) => {
    service.get(url, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}
/**
 * @param  {string} url
 * @param  {object} data={}
 */
const post = (url, data = {}, responseInfo = {}) => {
  data = removeEmpty(data)

  return new Promise((resolve, reject) => {
    service.post(url, data).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.log("请求出错", err)
      reject(err)
    })
  })
}

/**
 * @param  {string} url
 * @param  {object} data={}
 */
const put = (url, data = {}) => {
  data = removeEmpty(data)
  return new Promise((resolve, reject) => {
    service.put(url, data).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}
/**
 * @param  {string} url
 * @param  {object} params={}
 */
const del = (url) => {
  return new Promise((resolve, reject) => {
    service.delete(url, {}).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

export { service, fetch, post, put, del }