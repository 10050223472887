/*
 * @Author: gaylen
 * @Date: 2021-04-30 11:15:01
 * @LastEditTime: 2021-06-04 16:55:59
 * @LastEditors: gaylen
 * @Description: 
 * @FilePath: \web_admin\src\store\modules\user.js
 * 没啥想说的
 */

import { login } from '../../api/user'

const getDefaultState = () => {
  return {
   
  }
}

const state = getDefaultState()

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
}

const actions = {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
     
      return new Promise((resolve, reject) => {
        login(userInfo).then(res => {
          if(res.access_token){
            sessionStorage.setItem('TOKEN',res.access_token)
            commit('SET_TOKEN',res.access_token)
            resolve()
          }else{
            reject(res.resp_msg||'登录出错')
          }
          
        }).catch(error => {
          reject(error)
        })
      })
    },
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        sessionStorage.removeItem('TOKEN')
        commit('SET_TOKEN',null)
        resolve()
      })
    },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

