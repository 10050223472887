/*
 * @Author: gaylen
 * @Date: 2021-04-28 17:01:26
 * @LastEditTime: 2021-05-24 09:47:43
 * @LastEditors: gaylen
 * @Description: 
 * @FilePath: \web_admin\src\api\common.js
 * 没啥想说的
 */

import * as http from '../util/request'
/**
 * 测试
 * @param {object} data   
 */

/**
 * @description: 
 * @param {*} query
 * @return {*}
 */

  // 阿里云上传
 const ossFileUpdate = (query) => {
  return http.post('/aliyunOss/uploadFile',query)
  }
  // 获取基础数据
 const getBasicData= (query) => {
  return http.post('/mBaseData/getList',query)
  }
  // 新增基础数据
 const saveBasicData= (query) => {
  return http.post('/mBaseData/saveOrUpdate',query)
  }

 const getBasicDataByCdType= (query) => {
  return http.fetch('/mBaseData/selectByCdTypeDt',query)
  }


  

export {
  ossFileUpdate,
  getBasicData,
  getBasicDataByCdType,
  saveBasicData
}

