/*
 * @Author: gaylen
 * @Date: 2021-04-27 15:45:51
 * @LastEditTime: 2023-09-25 12:12:28
 * @LastEditors: gaylen it7@importgogo.com
 * @Description: 
 * @FilePath: \web_admin\src\main.js
 * 没啥想说的
 */
import { createApp ,configureCompat } from 'vue'
import router from './router'
import store from './store'



import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css'
// import 'element-plus/dist/index.css'
import './theme/index.css'

import locale from 'element-plus/lib/locale/lang/zh-cn'


import App from './App.vue'

configureCompat({ RENDER_FUNCTION: false,COMPONENT_V_MODEL: false });
const app = createApp(App)
app.use(ElementPlus, { locale })
app.use(router)
app.use(store)
Date.prototype.format = function(fmt) { 
    var o = { 
       "M+" : this.getMonth()+1,                 //月份 
       "d+" : this.getDate(),                    //日 
       "h+" : this.getHours(),                   //小时 
       "m+" : this.getMinutes(),                 //分 
       "s+" : this.getSeconds(),                 //秒 
       "q+" : Math.floor((this.getMonth()+3)/3), //季度 
       "S"  : this.getMilliseconds()             //毫秒 
   }; 
   if(/(y+)/.test(fmt)) {
           fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
   }
    for(var k in o) {
       if(new RegExp("("+ k +")").test(fmt)){
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
        }
    }
   return fmt; 
}   
app.mount('#app')
app.config.devtools = true




