/*
 * @Author: gaylen
 * @Date: 2021-04-30 11:15:01
 * @LastEditTime: 2021-06-02 16:35:44
 * @LastEditors: gaylen
 * @Description: 
 * @FilePath: \web_admin\src\store\modules\app.js
 * 没啥想说的
 */


const state = {
  sidebar: {
    opened:  true,
    withoutAnimation: false
  },
  device: 'desktop',
  hasElMessageBox:false,
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
   
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
  
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  chenge_hasElMessageBox:(state,value) =>{
    state.hasElMessageBox = value
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
